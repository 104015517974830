import { TFunction } from '@sortlist-frontend/translation';

import { DynamicIcon } from '_components/common/DynamicIcon';
import { IconNames } from '_core/type-utils';
import { categoryFiltered } from '_core/utils/public-links/category-filtered';
import { createLevels } from '_core/utils/public-links/createLevels';
import { services } from '_core/utils/public-links/services';
import { sortLevels } from '_core/utils/public-links/sort-levels';
import { AnchorType, Links } from '_types/public-api';

const getFilterNames = (links: Links | undefined) => {
  const filterCategories = links?.data
    .filter((link) => link.attributes.category.includes('filter'))
    .map((filter) => filter.attributes.category);

  return [...new Set(filterCategories)];
};

export type FilterLinks = {
  id: string;
  title: string;
  links: AnchorType[];
  icon: JSX.Element;
  placeholder?: string;
};

export const filterLinks = (links: Links | undefined, t: TFunction): FilterLinks[] => {
  if (links == null) return [];

  const filterCategories = getFilterNames(links);
  const levelsByCategory = filterCategories.map((category) => ({
    level: createLevels(links, services(links, category, categoryFiltered), sortLevels),
    id: category,
    metadata: links.data.find((link) => link.attributes.category === category)?.attributes.metadata,
  }));

  const filterLinks = levelsByCategory.map(({ level, id, metadata }) => ({
    links: level != null ? level['undefined']['undefined']['undefined'] : null,
    id,
    metadata,
  }));

  return filterLinks
    .sort((a, b) => a.id.localeCompare(b.id))
    .map((links, i) => ({
      id: links.metadata?.group ?? `filter-${i}`,
      title: t('longtail:filters.countries'),
      links: links.links ?? [],
      icon: (
        <DynamicIcon
          className="text-secondary-500 filter-icon"
          iconName={links?.metadata?.decorations?.icon_name as IconNames}
        />
      ),
      placeholder: links?.metadata?.item?.placeholder,
    }))
    .filter((link) => link.links.length > 0);
};
