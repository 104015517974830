'use client';

import {
  ArrowDropDownRounded,
  ArrowDropUpRounded,
  ArrowForwardRounded,
  CloseRounded,
  FilterListTwoTone,
} from '@mui/icons-material';
import { Portal } from '@mui/material';
import { Button } from '@sortlist-frontend/design-system/ssr';
import { useState } from 'react';

import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';

import { FilterLinks } from './utils';

type Props = {
  title: string;
  showSort: boolean;
  filters: FilterLinks[];
};

export const MobileFilter = (props: Props) => {
  const { showSort, title, filters } = props;

  const [open, setOpen] = useState(false);
  const [linksOpenIndex, setLinksOpenIndex] = useState(filters.length - 1);

  return (
    <div className="relative width-100">
      <Button
        data-testid={'filters-menu-button'}
        animation="none"
        size="sm"
        iconSize="lg"
        className={`flex-none small px-4 width-50`}
        buttonVariant="raised"
        buttonStyle="secondary"
        onClick={() => setOpen(!open)}
        id="find-agencies-btn"
        label={
          <div className={`layout-row layout-align-center-center gap-x-16 shadow-3 py-8`}>
            <div className="layout-row">
              <div className="layout-row layout-align-center-center">
                <FilterListTwoTone style={{ fontSize: '1rem' }} />
                <span className="small medium ml-8">{title}</span>
              </div>
            </div>
          </div>
        }
      />
      {open ? (
        <Portal container={document.getElementById('__main')}>
          <div
            className="absolute bg-neutral-100 shadow-3 rounded-md py-16"
            style={{
              zIndex: 100000,
              top: 100,
              width: '100%',
              height: '90%',
              overflow: 'auto',
            }}>
            <div className="layout-row layout-align-end-end p-16">
              <Button
                fab
                size={'xs'}
                iconSize={'md'}
                buttonStyle={'secondary'}
                buttonVariant={'light'}
                icon={<CloseRounded />}
                onClick={() => {
                  setOpen(false);
                }}
                id="mobile-menu-filter-close-btn"
              />
            </div>
            <span className="bold pl-32 text-secondary-900 pt-32 pb-1 h4">{title}</span>
            <div>
              {filters.map((filter, index) => {
                return (
                  <div key={filter.id}>
                    <button
                      className="px-32 py-16 rounded-sm border-bottom border-secondary-300 layout-row width-100 cursor-pointer"
                      key={filter.id}
                      onClick={() => {
                        if (linksOpenIndex === index) {
                          setLinksOpenIndex(-1);
                        } else {
                          setLinksOpenIndex(index);
                        }
                      }}
                      style={{
                        background: 'transparent',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        outline: 'none',
                        padding: 0,
                        margin: 0,
                      }}>
                      <div className="layout-column layout-align-center-center">{filter.icon}</div>
                      <span className="flex px-8 py-4 layout-row layout-align-start-center mx-4 small">
                        {filter.placeholder}
                      </span>
                      {linksOpenIndex === index ? (
                        <ArrowDropUpRounded className="ml-8" />
                      ) : (
                        <ArrowDropDownRounded className="ml-8" />
                      )}
                    </button>
                    {linksOpenIndex === index
                      ? filter.links.map((link) => (
                          <a
                            key={link.anchor}
                            className="text-secondary-500 px-32 py-16 layout-row layout-align-start-center border-bottom border-secondary-300 bg-secondary-100 small layout-align-space-between"
                            href={getLocalizedUrl(link)}>
                            {link.anchor}
                            <ArrowForwardRounded className="ml-8 small" />
                          </a>
                        ))
                      : null}
                  </div>
                );
              })}
            </div>
          </div>
        </Portal>
      ) : null}
    </div>
  );
};
