import { ArrowDropDownRounded, FilterListTwoTone } from '@mui/icons-material';
import { TFunction } from '@sortlist-frontend/translation/server-only';
import { Fragment } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { Menu } from '_components/common/Menu/Menu';
import { NavigationData } from '_components/layout/Layout';
import { getLocalizedUrl } from '_core/utils/public-links/getLocalizedUrl';
import { filterLinks } from '_features/longtail/sections/ContextualBar/utils';
import { Links } from '_types/public-api';

import { LayoutSwitch } from './LayoutSwitch/LayoutSwitch';
import { MobileFilter } from './MobileFilter';
import { sortOptions } from './Sort/utils';

export type ContextualBarProps = {
  links?: Links;
  showSort: boolean;
  className?: string;
  hideSort?: boolean;
  showFilters: boolean;
  navigationData: NavigationData;
  agencyCardsLayout: string;
  showLayoutSwitch?: boolean;
  t: TFunction;
};

export const ContextualBar = (props: ContextualBarProps) => {
  const {
    links,
    showSort,
    showFilters,
    hideSort = false,
    navigationData,
    t,
    showLayoutSwitch,
    className,
    agencyCardsLayout,
  } = props;
  const { resolvedUrl, query: queryFromUrl, origin, sort } = navigationData;
  const filters = filterLinks(links as any, t);
  const domainInfo = DomainInfo.getFromOrigin(origin);

  const sortOptionsList = sortOptions(t);
  const selectedSortOption = sortOptionsList.find((option) => option.value === sort);

  const getSortUrl = (sortBy: string) => {
    const routeChunks = resolvedUrl?.split('?') || [];
    const asPath = routeChunks[0].replace('#content', '');

    const query = new URLSearchParams(queryFromUrl);
    query.set('sort', sortBy);
    query.delete('page');
    if (sortBy === 'position') {
      query.delete('sort');
      const queryParams = query.toString() ? `?${query.toString()}` : '';
      return `${domainInfo?.getOriginUrl()}${asPath}${queryParams}#content`;
    } else {
      return `${domainInfo?.getOriginUrl()}${asPath}?${query.toString()}#content`;
    }
  };

  return (
    <Fragment>
      <div
        className={`${className ?? ''} pl-16 sticky layout-row layout-align-space-between hide-gt-sm pb-16`}
        style={{ top: 0, zIndex: 95, ...(showSort && !showFilters ? { marginLeft: 'auto', width: '50%' } : {}) }}>
        {showFilters ? (
          <MobileFilter showSort={showSort} title={t('longtail:filters.title')} filters={filters} />
        ) : null}
        {showSort ? (
          <Menu
            containerClassName="width-100 pl-8"
            buttonClassName="width-100"
            buttonVariant="raised"
            label={
              <div className={`layout-row layout-align-center-center gap-x-16`}>
                <div className="layout-row">
                  <div className="layout-row layout-align-center-center py-8">
                    <FilterListTwoTone style={{ fontSize: '1rem' }} />
                    <span className="small medium ml-8">{t('longtail:sortBy.title')}</span>
                  </div>
                </div>
              </div>
            }
            showArrow={false}
            items={
              sortOptions(t).map((option) => ({
                label: (
                  <div className="layout-column py-4">
                    <span className="medium small">{option.label}</span>
                    <span className="text-wrap text-secondary-500" style={{ fontSize: 12 }}>
                      {option.description}
                    </span>
                  </div>
                ),
                className: 'sort-menu-item',
                dataTestid: option.value,
                href: getSortUrl(option.value),
                visible: true,
                menuClassName: 'width-100',
              })) as any
            }
          />
        ) : null}
      </div>
      <div
        className={`${className ?? ''} sticky hide show-gt-sm ${showSort && !showFilters ? 'width-30' : 'width-100 border-bottom border-secondary-300'}`}
        style={{ top: 0, zIndex: 95, ...(showSort && !showFilters ? { marginLeft: 'auto' } : {}) }}>
        <div className="layout-row layout-align-space-between container-lg px-0">
          {showFilters ? (
            <div className="pl-16 layout-row flex-75 layout-align-start-center gap-x-16">
              {filters.map((filter) => (
                <div className="flex-30" key={filter.id}>
                  <Menu
                    menuClassName="width-100"
                    className="width-100 p-0"
                    buttonVariant="raised"
                    label={
                      <div className="rounded-sm layout-row width-100" key={filter.id}>
                        <div className="bg-secondary-100 border-right rounded-top-left-sm rounded-bottom-left-sm border-secondary-300 p-8 layout-column layout-align-center-center">
                          {filter.icon}
                        </div>
                        <span className="flex px-8 py-4 layout-row layout-align-start-center mx-4 small">
                          {filter.placeholder}
                        </span>
                        <ArrowDropDownRounded className="px-8" fontSize="large" />
                      </div>
                    }
                    showArrow={false}
                    items={filter.links.map((link) => ({
                      label: link.anchor,
                      dataTestid: link.anchor,
                      href: getLocalizedUrl(link),
                      visible: true,
                    }))}
                  />
                </div>
              ))}
            </div>
          ) : null}
          {showSort ? (
            <Fragment>
              {showLayoutSwitch ? <LayoutSwitch agencyCardsLayoutValue={agencyCardsLayout} /> : null}
              <div
                className={`layout-row layout-align-start-center ml-16 ${showFilters ? 'flex-35' : 'width-100'} ${hideSort === true ? 'hide' : ''}`}>
                <Menu
                  dataTestId="sort-select"
                  containerClassName="width-100 p-0"
                  buttonClassName="width-100 p-0"
                  buttonVariant="raised"
                  label={
                    <div className="rounded-sm layout-row width-100">
                      <div className="bg-secondary-100 border-right rounded-top-left-sm rounded-bottom-left-sm border-secondary-300 p-8 layout-column layout-align-center-center small">
                        {t('longtail:sortBy.title')}
                      </div>
                      <span className="flex px-8 py-4 layout-row layout-align-start-center mx-4 small">
                        {selectedSortOption?.label ?? t('longtail:sortBy.recommendedLabel')}
                      </span>
                      <ArrowDropDownRounded className="px-8" fontSize="large" />
                    </div>
                  }
                  showArrow={false}
                  menuClassName={'width-100 sort-menu'}
                  items={sortOptions(t).map((option) => ({
                    label: (
                      <div className="layout-column py-4">
                        <span className="medium small">{option.label}</span>
                        <span className="text-wrap text-secondary-500" style={{ fontSize: 12 }}>
                          {option.description}
                        </span>
                      </div>
                    ),
                    className: 'sort-menu-item',
                    dataTestid: option.value,
                    href: getSortUrl(option.value),
                    visible: true,
                  }))}
                />
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};
