'use client';

import { Button, ButtonProps } from '@sortlist-frontend/design-system/ssr';

import { CTA, OPEN_BRIEFING, useBriefingMessage } from '../versions/NormalBriefing/BriefingDialog';

type BriefingEmbedButton = {
  cta: CTA;
  expertiseId?: number;
} & ButtonProps;

export const BriefingEmbedButton = (props: BriefingEmbedButton) => {
  const { cta, expertiseId, ...buttonProps } = props;

  const { sendMessage } = useBriefingMessage();

  return (
    <Button
      {...buttonProps}
      onClick={() => {
        sendMessage(OPEN_BRIEFING, { cta: cta, expertiseId: expertiseId });
      }}
    />
  );
};
